<ion-app [class.mobile]="!IsWebVersion()">
  <ion-loading [isOpen]="!!(currentUserState.updating$ | async)"></ion-loading>

  <ion-router-outlet></ion-router-outlet>

  <dr-lost-connection></dr-lost-connection>

  @if (authState.isAuthenticated()) {
    <dr-current-user-connector
      (connected)="profileConnected()"
    ></dr-current-user-connector>
  }
</ion-app>
