import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { auth, AuthModule, noAuth } from '@dr/auth';
import { hasFeature } from '@dr/features';
import { UsersModule } from '@dr/user';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [noAuth],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'user/:userId',
    canActivate: [auth],
    loadComponent: () =>
      import('@dr/profile').then((m) => m.UserProfileComponent),
  },
  {
    path: 'company/:companyId',
    canActivate: [auth],
    loadComponent: () =>
      import('@dr/profile').then((m) => m.CompanyProfileComponent),
  },
  {
    path: 'requests',
    canActivate: [auth],
    loadComponent: () =>
      import('@dr/connections').then((m) => m.RequestsListComponent),
  },
  {
    path: 'notifications',
    canActivate: [auth],
    loadComponent: () =>
      import('@dr/notifications').then((m) => m.NotificationsComponent),
  },
  {
    path: 'goals/create',
    canActivate: [auth],
    loadComponent: () => import('@dr/goals').then((m) => m.GoalSurveyComponent),
  },
  {
    path: 'contact-form',
    canActivate: [auth, () => hasFeature('cf')],
    loadComponent: () =>
      import('@dr/contact-form').then((m) => m.ContactFormComponent),
    data: {
      useBackButton: true,
    },
  },
  {
    path: 'connections',
    canActivate: [auth],
    children: [
      {
        path: 'add/user',
        loadComponent: () =>
          import('@dr/connections').then(
            (m) => m.AddUserToUserConnectionComponent
          ),
      },
      {
        path: 'add/company',
        loadComponent: () =>
          import('@dr/connections').then(
            (m) => m.AddUserToCompanyConnectionComponent
          ),
      },
      {
        path: 'users/:id',
        loadComponent: () =>
          import('@dr/connections').then(
            (m) => m.UserToUsersConnectionsListComponent
          ),
      },
      {
        path: 'companies/:id',
        loadComponent: () =>
          import('@dr/connections').then(
            (m) => m.UserToCompaniesConnectionsListComponent
          ),
      },
      {
        path: '**',
        redirectTo: 'users',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'contact-form/chat/:id',
    canActivate: [auth, () => hasFeature('cf')],
    loadComponent: () =>
      import('@dr/contact-form').then((m) => m.ContactFormChatComponent),
  },
  {
    path: 'settings',
    canActivate: [auth],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@dr/settings').then((m) => m.SettingsComponent),
      },
      {
        path: 'general',
        canActivate: [() => hasFeature('cu')],
        loadComponent: () =>
          import('@dr/settings').then((m) => m.UserInfoSettingsComponent),
      },
      {
        path: 'system',
        canActivate: [() => hasFeature('cd')],
        loadComponent: () =>
          import('@dr/settings').then((m) => m.SystemSettingsComponent),
      },
      {
        path: 'delete-profile',
        canActivate: [() => hasFeature('dp')],
        loadComponent: () =>
          import('@dr/settings').then((m) => m.DeleteDataComponent),
        data: {
          title: 'delete_data.delete_profile',
          contactMessage: 'delete_data.delete_profile_contact_message',
          message: 'delete_data.delete_profile_message',
          imgSrc: '/assets/images/sad2.gif',
        },
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    canActivate: [auth],
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    AuthModule,
    UsersModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
